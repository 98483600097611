import React, { PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_CURRENCY,
  ItemCategoryDto,
  ItemConditionDto,
  ItemInfoDto,
  ItemType,
  SellingMethod,
} from '../../../services/Item/itemService.dto';
import { UserDetailsDto } from '../../../services/User/userService.dto';
import { useGlobalData } from '../../../providers/GlobalDataProvider';
import { CampaignAdminDto } from '../../../services/Campaign/campaignService.dto';
import { convertFormDataToEditItemDto, convertFormDataToNewItemDto } from '../utils/converters';
import { itemService } from '../../../services/Item/itemService';
import { validateItemForm } from '../utils/validators';
import { dateUtils } from '../../../utils/dateUtils';
import { useUserDetails } from '../../users/hooks/useUserDetails';
import { useCampaignDetails } from '../../campaigns/hooks/useCampaignDetails';
import { ImageDto, useUploadImages } from '../../../services/Image/useUploadImages';
import { UploadContext } from '../../../services/utils/imageUploadService';
import { DEFAULT_OBJECT_LOCATION, LocationInfoDto } from '../../../services/Shipment/shipmentService.dto';
import { useError } from '../../../providers/useError';
import { auctionService } from '../../../services/Auction/auctionService';
import { AuctionDto } from '../../../services/Auction/auctionService.dto';
import { useItemDetails } from './useItemDetails';

export interface ItemFormData {
  id?: number;
  user?: UserDetailsDto;
  type?: ItemType;
  title?: string;
  description?: string;
  sellingMethod?: SellingMethod;
  condition?: ItemConditionDto;
  category?: ItemCategoryDto;
  price?: number;
  currency: string;
  negotiable: boolean;
  auctionEndDate?: Date;
  quantity?: number;
  canLocalPickupDelivery: boolean;
  canNationwideShipping: boolean;
  itemLocation: LocationInfoDto;
  weightUpTo?: number;
  photos: ImageDto[];
  videoUrl?: string;
  campaign?: CampaignAdminDto;
}

interface ItemContextType {
  formData: ItemFormData;
  itemId?: number;
  item?: ItemInfoDto;
  auction?: AuctionDto;
  setUser: (user?: UserDetailsDto) => void;
  setType: (type?: ItemType) => void;
  setSellingMethod: (sellingMethod?: SellingMethod) => void;
  setTitle: (title?: string) => void;
  setDescription: (description?: string) => void;
  setCondition: (condition?: ItemConditionDto) => void;
  setCategory: (category?: ItemCategoryDto) => void;
  setPrice: (price?: number) => void;
  setCurrency: (currency: string) => void;
  setNegotiable: (negotiable: boolean) => void;
  setAuctionEndDate: (endDate?: Date) => void;
  setQuantity: (quantity?: number) => void;
  setCanLocalPickupDelivery: (canLocalPickupDelivery: boolean) => void;
  setCanNationwideShipping: (canNationwideShipping: boolean) => void;
  setItemLocation: (itemLocation: LocationInfoDto) => void;
  setWeightUpTo: (weightUpTo?: number) => void;
  setCampaign: (campaign?: CampaignAdminDto) => void;
  addPhoto: (file: File) => void;
  deletePhoto: (id: string) => void;
  movePhoto: (from: number, to: number) => void;
  setVideoUrl: (videoUrl?: string) => void;
  errorMessages: string[];
  isLoading: boolean;
  saveItem: () => void;
}

const context = React.createContext<ItemContextType | null>(null);

export interface ItemContextProps extends PropsWithChildren {
  itemId?: number;
  onItemSaved: () => void;
}

const ItemContext = ({ itemId, onItemSaved, children }: ItemContextProps) => {
  const { itemConditions, itemCategories, paymentConfig } = useGlobalData();
  const { errorMessage } = useError();

  const { item, auction } = useItemDetails(itemId);
  const { user: currentUser } = useUserDetails(item?.ownerId);
  const { campaign: currentCampaign } = useCampaignDetails(item?.supportedCampaignId);

  const [isLoading, setIsLoading] = useState(!!itemId && !item);

  const [user, setUser] = useState<UserDetailsDto>();
  const [type, setType] = useState<ItemType | undefined>('REGULAR');
  const [sellingMethod, setSellingMethod] = useState<SellingMethod | undefined>('STANDARD');
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [condition, setCondition] = useState<ItemConditionDto | undefined>(itemConditions[0]);
  const [category, setCategory] = useState<ItemCategoryDto | undefined>(
    itemCategories.filter(category => category.itemType === type)[0]
  );
  const [price, setPrice] = useState<number | undefined>();
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [negotiable, setNegotiable] = useState(true);
  const [auctionEndDate, setAuctionEndDate] = useState<Date | undefined>(dateUtils.dateWithDaysOffset(new Date(), 1));
  const [quantity, setQuantity] = useState<number | undefined>();
  const [canLocalPickupDelivery, setCanLocalPickupDelivery] = useState(true);
  const [canNationwideShipping, setCanNationwideShipping] = useState(true);
  const [itemLocation, setItemLocation] = useState<LocationInfoDto>(DEFAULT_OBJECT_LOCATION);
  const [weightUpTo, setWeightUpTo] = useState<number | undefined>(0.5);
  const [campaign, setCampaign] = useState<CampaignAdminDto>();
  const [videoUrl, setVideoUrl] = useState<string>();

  const { photos, setPhotoUrls, addPhoto, deletePhoto, movePhoto } = useUploadImages(
    UploadContext.ITEM,
    item?.imageUrlList
  );

  useEffect(() => {
    if (item) {
      setType(item.itemType);
      setSellingMethod(item.sellingMethod);
      setTitle(item.title);
      setDescription(item.description);
      setCondition(itemConditions.find(condition => condition.code === item.condition));
      setCategory(itemCategories.find(category => category.code === item.category));
      setPrice(item.price);
      setCurrency(item.currency);
      setNegotiable(item.canAcceptLoHiOffers);
      setQuantity(item.unitsQuantity);
      setCanLocalPickupDelivery(item.canLocalPickUpDelivery);
      setCanNationwideShipping(item.canNationwideShipping);
      setItemLocation(item.itemLocation);
      setWeightUpTo(item.weightUpTo);
      setPhotoUrls(item.imageUrlList);
      setVideoUrl(item.videoUrl);
    }
  }, [item]);

  useEffect(() => {
    if (auction) {
      setAuctionEndDate(dateUtils.parseBackendDate(auction.endDateTime));
      setPrice(auction.minimumPrice);
    }
  }, [auction]);

  useEffect(() => setUser(currentUser), [currentUser]);
  useEffect(() => setCampaign(currentCampaign), [currentCampaign]);

  useEffect(() => {
    if (itemId) {
      setIsLoading(!item || !currentUser || !currentCampaign);
    }
  }, [itemId, item, currentUser, currentCampaign]);

  const saveItem = async () => {
    setIsLoading(true);
    if (!itemId) await addItem();
    else await editItem(itemId);
  };

  const addItem = async () => {
    const newItemDto = convertFormDataToNewItemDto(formData);
    if (!formData.user) return;

    try {
      await itemService.addItemByEmployee(newItemDto, formData.user.id);
      onItemSaved();
    } catch (error: any) {
      errorMessage(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const editItem = async (itemId: number) => {
    const editItemDto = convertFormDataToEditItemDto(formData, itemId);
    try {
      await itemService.editItemByEmployee(editItemDto);
      if (sellingMethod === 'AUCTION' && editItemDto.auction && editItemDto.auction.endDateTime && auction) {
        const initialEndDate = dateUtils.parseBackendDate(auction.endDateTime).getTime();
        const newEndDate = new Date(editItemDto.auction.endDateTime).getTime();
        const auctionDataChanged =
          editItemDto.auction.minimumPrice !== auction.minimumPrice || newEndDate !== initialEndDate;
        if (auctionDataChanged) await auctionService.editAuction(auction.id, editItemDto.auction);
      }
      onItemSaved();
    } catch (error: any) {
      const errorData = error?.response?.data;
      if (errorData?.message) errorMessage(errorData.message);
      else if (errorData?.errors) errorMessage(errorData.errors.join(', '));
      else errorMessage();
    } finally {
      setIsLoading(false);
    }
  };

  const formData: ItemFormData = {
    id: itemId,
    user,
    type,
    sellingMethod,
    title,
    description,
    condition,
    category,
    price,
    currency,
    negotiable,
    auctionEndDate,
    quantity,
    canLocalPickupDelivery,
    canNationwideShipping,
    itemLocation,
    photos,
    videoUrl,
    weightUpTo,
    campaign,
  };

  const errorMessages = useMemo(() => validateItemForm(formData, item, paymentConfig), [formData, item, paymentConfig]);

  return (
    <context.Provider
      value={{
        itemId,
        item,
        auction,
        formData,
        setUser,
        setType,
        setSellingMethod,
        setTitle,
        setDescription,
        setCondition,
        setCategory,
        setPrice,
        setCurrency,
        setNegotiable,
        setAuctionEndDate,
        setQuantity,
        setCanLocalPickupDelivery,
        setCanNationwideShipping,
        setItemLocation,
        setWeightUpTo,
        setCampaign,
        addPhoto,
        deletePhoto,
        movePhoto,
        setVideoUrl,
        errorMessages,
        isLoading,
        saveItem,
      }}>
      {children}
    </context.Provider>
  );
};

const useItemForm = () => {
  const itemContext = useContext(context);
  if (itemContext == null) {
    throw new Error('useItemForm() called outside of an ItemContext?');
  }
  return itemContext;
};

export { ItemContext, useItemForm };
